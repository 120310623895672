import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const ContactInfo = ({ name, email, location }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <motion.div
      className="contactInfo"
      ref={ref}
      animate={inView ? { x: 0, opacity: 1 } : { x: "10vw", opacity: 0 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
    >
      <h4 className="contentTitle">Contact Information</h4>
        <p className="infoDescription">Discover more about my work and connect with me on LinkedIn <br/>or contact me via email or Discord.</p>
      <ul className="listInfo">
        <li>

              <h6 className="infoType">Name&nbsp;
              </h6>
              <span className="infoValue">{name}</span>

        </li>
        <li>
              <h6 className="infoType">Email&nbsp;</h6>
              <span className="infoValue">
          <a href={`mailto:${email}`}>{email}</a>
        </span>
        </li>
          <li>
              <h6 className="infoType">Discord&nbsp;</h6>
              <span className="infoValue">
          <span className="infoValue">tr3x.xyz</span>
        </span>
          </li>
          
      </ul>

    </motion.div>
  );
};

export default ContactInfo;
