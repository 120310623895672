import AboutMe from "../../components/AboutMe";
import PageHeader from "../../components/PageHeader";
import Experiences from "../../components/Experiences";
import SocialIcons from "../../components/SocialIcons";
import "./about.css";

const About = ({ name, age, location, brand, email, availability }) => {
  
return (
    <section className="about">
      <PageHeader title="About Me" description="" />
      <AboutMe name={name} age={age} location={location} brand={brand} email={email} availability={availability} />
        <Experiences />
        <div className="mobileSocialIcons">
          <SocialIcons />
        </div>
        
    </section>
  );
};

export default About;
