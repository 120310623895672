import { useRef, useEffect } from "react";

const Draw = () => {
  const canvasRef = useRef(null);
  const drawing = useRef(false);

  // Function to start drawing
  const startDrawing = (x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    drawing.current = true;
    ctx.moveTo(x, y);
  };

  // Function to draw line
  const drawLine = (x, y) => {
    if (!drawing.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.lineTo(x, y);
    ctx.lineWidth = 2;
    ctx.lineCap = "round";
    ctx.strokeStyle = "#ffffff";
    ctx.stroke();
  };

  // Function to stop drawing
  const stopDrawing = () => {
    drawing.current = false;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "rgba(0, 0, 0, 0)"; // Transparent background
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Function to handle mouse and touch events
    const handleEvent = (e) => {
      e.preventDefault();
      let x, y;
      if (e.type.includes('touch')) {
        const touch = e.touches[0];
        x = touch.clientX;
        y = touch.clientY;
      } else {
        x = e.offsetX;
        y = e.offsetY;
      }

      if (e.type === 'mousedown') {
        startDrawing(x, y);
      } 
      else if (e.type === 'touchstart') {
        startDrawing(x, y-100);
      }
      else if (e.type === 'mousemove') {
        drawLine(x, y);
      }
      else if (e.type === 'touchmove') {
        drawLine(x, y-100);
      }
      else {
        stopDrawing();
      }
    };

    // Add mouse and touch event listeners
    canvas.addEventListener('mousedown', handleEvent);
    canvas.addEventListener('mousemove', handleEvent);
    canvas.addEventListener('mouseup', handleEvent);
    canvas.addEventListener('mouseleave', handleEvent);
    canvas.addEventListener('touchstart', handleEvent);
    canvas.addEventListener('touchmove', handleEvent);
    canvas.addEventListener('touchend', handleEvent);

    // Resize canvas to fill window dynamically
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    // Cleanup function to remove event listeners
    return () => {
      canvas.removeEventListener('mousedown', handleEvent);
      canvas.removeEventListener('mousemove', handleEvent);
      canvas.removeEventListener('mouseup', handleEvent);
      canvas.removeEventListener('mouseleave', handleEvent);
      canvas.removeEventListener('touchstart', handleEvent);
      canvas.removeEventListener('touchmove', handleEvent);
      canvas.removeEventListener('touchend', handleEvent);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <canvas
        ref={canvasRef}
        style={{ position: "fixed", top: 100, left: 0 }}
        width={window.innerWidth}
        height={window.innerHeight}
    />
);
};

export default Draw;
