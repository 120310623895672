import PageHeader from "../../components/PageHeader";
import SocialIcons from "../../components/SocialIcons";
import Form from "../../components/Form";
import ContactInfo from "../../components/ContactInfo";

const Contact = ({ name, email, location }) => {
  return (
    <section className="contact">
      <PageHeader title="Contact" description="" />
        <div className="contactWrap container">
            <div className="row">
                <ContactInfo name={name} location={location} email={email} />
            </div>
        </div>
      <SocialIcons />
    </section>
  );
};

export default Contact;
