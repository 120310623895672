import React, { useEffect, useState } from "react";
import Project from "../../components/Project"; // Adjust the path as necessary
import SocialIcons from "../../components/SocialIcons";
import "./projects.css"; // Ensure this path matches your file structure
// Import FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faGooglePlay } from "@fortawesome/free-brands-svg-icons";

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showTopBtn, setShowTopBtn] = useState(false);

    useEffect(() => {
        setIsLoading(true); // Start loading before fetching data
        const fetchProjects = async () => {
            try {
                const response = await fetch(`https://api.github.com/users/tr3xxx/repos?type=public&sort=updated`);
                const repos = await response.json();
                const projectData = repos.map(repo => ({
                    id: repo.id,
                    name: repo.name,
                    lastUpdated: new Date(repo.updated_at).toLocaleDateString("en-US"),
                    link: repo.html_url,
                    type: 'github' // Identify as GitHub project
                }));

                // Manually add some projects with Google Play links
                const manualProjects = [
                  {
                    id: 'gp-1',
                    name: "Bundesliga360 GooglePlay App",
                    lastUpdated: "08/31/2023",
                    link: "https://play.google.com/store/apps/details?id=xyz.tr3x.bundesliga360",
                    type: 'googleplay' // Identify as Google Play project
                  },
                  {
                    id: 'gp-2',
                    name: "TaskTarea GooglePlay App",
                    lastUpdated: "30/12/2022",
                    link: "https://play.google.com/store/apps/details?id=xyz.tr3x.TaskTarea",
                    type: 'googleplay'
                  },
                  {
                    id: 'gp-3',
                    name: "StackCO GooglePlay App",
                    lastUpdated: "08/04/2022",
                    link: "https://play.google.com/store/apps/details?id=com.tr3xTech.StackCO",
                    type: 'googleplay'
                  }
                ];

                // Combine and sort projects
                const combinedProjects = [...projectData, ...manualProjects].sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                setProjects(combinedProjects);
            } catch (error) {
                console.error("Failed to fetch projects", error);
            } finally {
                setIsLoading(false); // End loading regardless of outcome
            }
        };

        fetchProjects();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setShowTopBtn(window.scrollY > 300);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className="portfolio">
            <h3 className="pageTitle">Projects</h3>
            <div className="projects-container">
                {isLoading ? (
                    <div className="loader"></div>
                ) : (
                    <table className="projects-table">
                        <thead>
                            <tr>
                                <th>Project</th>
                                <th>Last Updated</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {projects.map((project) => (
                                <tr key={project.id}>
                                    <td>
                                        {project.type === 'github' ? (
                                            <span><FontAwesomeIcon icon={faGithub} style={{ marginRight: '5px' }} />{project.name}</span>
                                        ) : project.type === 'googleplay' ? (
                                            <span><FontAwesomeIcon icon={faGooglePlay} style={{ marginRight: '5px' }} />{project.name}</span>
                                        ) : (
                                            project.name
                                        )}
                                    </td>
                                    <td>{project.lastUpdated}</td>
                                    <td><a href={project.link} className="project-link" target="_blank" rel="noopener noreferrer">View</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {showTopBtn && (
                <button className="back-to-top" onClick={scrollToTop}>
                    ↑
                </button>
            )}
            <SocialIcons />
        </div>
    );
};

export default Projects;
