
import Typewriter from "typewriter-effect";
import "./landing.css";
import Draw from "../../components/Draw";
import {Spotify} from "react-spotify-embed";
import SocialIcons from "../../components/SocialIcons";


const Landing = ({name,age}) => {

   
  
return (
    <section className="landing">
      <Draw />
      <div className="textContainer" >
        <h1 className="name" >
          {name}
        </h1>
        <div className="description">
  <Typewriter
    options={{
      loop: true,
      deleteSpeed: 100, // Adjust the speed of deletion
      delay: 100, // Adjust the typing speed
    }}
    onInit={(typewriter) => {
      typewriter
        .typeString("Welcome to my Portfolio!")
        .pauseFor(1500)
        .deleteAll()
        .typeString(`I'm a ${age} year old computer science student & software developer.`)
        .pauseFor(1500)
        .deleteAll()
        .typeString("Feel free to explore my work using the navigation menu.")
        .pauseFor(1500)
        .deleteAll()
        .typeString("Or draw something using the landing page and your mouse or fingers.")
        .pauseFor(1500)
        .deleteAll()
        .typeString("Discover projects, from web development to machine learning.")
        .pauseFor(1500)
        .deleteAll()
        .typeString("Engage with my journey through code and creativity.")
        .pauseFor(1500)
        .deleteAll()
        .start();
    }}
  />
</div>

        <div className="spotify-div">
          <Spotify wide link="https://open.spotify.com/track/4bTEd6lA0HER3YV8jeJQBA?si=1bd8d0b16d724ae3" />
        </div>

      </div>

      <div className="socialIconsLanding">
      <SocialIcons />
      </div>
    </section>
  );
  
};


export default Landing;
